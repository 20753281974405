<template>
<div class="main">
      <div class="panels">
        <h2 style="margin-bottom:10px;color:white">Blog Posts </h2>
        <v-btn class="ma-2" @click="createblog = true" style="color:#263AA2" v-show="loggedin">Add</v-btn>
        <v-dialog v-model="createblog" max-width="50%" :fullscreen="$vuetify.breakpoint.smAndDown">
            <form @submit.prevent="add" class="blog">
            <validation-provider
                v-slot="{ errors }"
                name="Title"
                rules="required"
            >
            <v-text-field
                v-model="title"
                :error-messages="errors"
                label="Title"
            ></v-text-field>
            </validation-provider>

            <label style="color:#636c72;margin-bottom:1%;">Content</label>
            <vue-editor
                v-model="content"
                :error-messages="errors"
            ></vue-editor>
            <v-btn
                color="primary"
                class="mr-4"
                type="submit"
            >
            Submit
            </v-btn>
            <v-btn
                class="ma-2"
                @click="createblog = false"
            >
            Close
            </v-btn>
            </form>
        </v-dialog>
        <v-expansion-panels>
            <v-expansion-panel
            v-for="(item,i) in this.length"
            :key="i"
            style="background:#467cc1;color:white;"
            >
            <v-expansion-panel-header>
                <div>{{ data[i].title }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="paneldata">
                <div class="panel">
                    <p class="card-text"><small >By : {{ data[i].created_by }}</small></p>
                    <p style="white-space: pre-line;">{{ data[i].content | strippedContent }}</p>
                </div>
                </div>
                <v-btn
                    class="ma-2"
                    style="color:#263AA2"
                    @click="updateblog = true"
                    v-show="loggedin"
                >
                    Edit
                </v-btn>
                <v-dialog v-model="updateblog" max-width="50%" :fullscreen="$vuetify.breakpoint.smAndDown">
                    <form @submit.prevent="update" class="blog">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Title"
                        rules="required"
                    >
                    <v-text-field
                        :value="data[i].title"
                        ref="title"
                        :error-messages="errors"
                        label="Title"
                        disabled
                    ></v-text-field>
                    </validation-provider>
                    <label style="color:#636c72;margin-bottom:1%;">Content</label>
                    <vue-editor
                        :value="data[i].content"
                        ref="content"
                        :error-messages="errors"
                    ></vue-editor>
                    <v-btn
                        color="primary"
                        class="mr-4"
                        type="submit"
                    >
                    Submit
                    </v-btn>
                    <v-btn
                        class="ma-2"
                        @click="updateblog = false"
                    >
                    Close
                    </v-btn>
                    </form>
                </v-dialog>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </div>
    <v-alert outlined transition="slide-y-transition" type="success" text :value="updatedpost"> Blog Post Updated successfully. </v-alert>
    <v-alert outlined type="error" text :value="failedupdatepost"> Failed to Update Blog Post. </v-alert>
    <v-alert outlined transition="slide-y-transition" type="success" text :value="addedpost"> Blog Post Created successfully. </v-alert>
    <v-alert outlined type="error" text :value="failedaddpost"> Failed to Create Blog Post. </v-alert>
</div>
</template>

<script>
import { required, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { VueEditor } from "vue2-editor";
import axios from 'axios';

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('min', {
    ...min,
    message: '{_field_} must have atlest {length} characters',
  })

  export default {
    name: 'Blog',
    components: {
      ValidationProvider,
      ValidationObserver,
      VueEditor,
    },
    data: () => ({
        length: '',
        loggedin: false,
        createblog: false,
        updatedpost: false,
        failedupdatepost: false,
        addedpost: false,
        failedaddpost: false,
        updateblog: false,
    }),
    beforeMount() {
        this.checkloggedin();
    },

    mounted () {
        this.getblogposts();
    },

    filters: {
        strippedContent: function(string) {
            return string.replace(/<\/?[^>]+>/ig, "\n"); 
        }
    },

    methods: {
        checkloggedin() {
            let data = JSON.parse(sessionStorage.getItem("data"))
            console.log(data)
            if (data && data['blog']=='Y') {
              this.loggedin = true
            }
        },
        getblogposts() {
            let config = {
                method : 'get',
                url : `https://kharame-devices.herokuapp.com/getBlogPosts`,
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.length = response.data.message.length
                this.data = response.data.message
            })
        },
        add() {
            let data = {
                title : this.title,
                content: this.content,
            };
            let config = {
                method : 'post',
                url : `https://kharame-devices.herokuapp.com/createBlogPost`,
                headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                },
                data: data
            };
            axios(config)
            .then((response) => {
            if (response.data && response.data.status === "S") {
              this.addedpost = true;
              this.createblog = false;
              this.getblogposts();
              setTimeout(() => this.addedpost = false, 5000);
            } else {
              this.failedaddpost = true;
              this.createblog = false
            }
            })
        },
        update() {
            let data = {
                title : this.$refs.title[0].value,
                content: this.$refs.content[0]._data.quill.editor.delta.ops[0].insert,
            };
            let config = {
                method : 'post',
                url : `https://kharame-devices.herokuapp.com/updateBlogPost`,
                headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                },
                data: data
            };
            axios(config)
            .then((response) => {
            if (response.data && response.data.status === "S") {
              this.updatedpost = true;
              this.updateblog = false;
              this.getblogposts();
              setTimeout(() => this.updatedpost = false, 5000);
            } else {
              this.failedupdatepost = true;
              this.updateblog = false
            }
            })
        },
    },
  }
</script>
<style scoped>
.main {
  width : 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
}
.blog {
  background: white;
  padding: 10%;
}
.panels {
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
}
.panel {
    margin-left: 2.5%;
}
@media (max-width: 550px) {
    .panel {
        margin-top: 10%;
    }
}
</style>